import React from 'react';

const Input = (props) => {
    return (
        <div className="form-group col-md-4">
            <label htmlFor={props.id}>{props.title}</label>
            <input
                className="form-control"
                id={props.name}
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                {...props}
            />
        </div>
    );
};

export default Input;
