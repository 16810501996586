import Form from './components/Form';

function App() {
    return (
        <div className="row">
            <div className="settings">
                <Form />
            </div>
        </div>
    );
}

export default App;
