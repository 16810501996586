import React, { useState } from 'react';
import Input from './Input';

const Form = (props) => {
    const [payInAmount, setPayInAmount] = useState(0.0);
    const [contributionAmount, setContributionAmount] = useState(0.0);

    const asMoney = (value) => {
        return `£ ${value.toFixed(2)}`;
    };

    const updatedTotalAmount = (total) => {
        total = parseFloat(total);
        if (total > 0) {
            const payInAmount = Math.ceil(total * 0.8 * 100) / 100;
            const contributionAmount = total - payInAmount;
            const maximumContribution = 500;
            if (contributionAmount > maximumContribution) {
                setContributionAmount(maximumContribution);
                setPayInAmount(
                    payInAmount + (contributionAmount - maximumContribution)
                );
            } else {
                setPayInAmount(payInAmount);
                setContributionAmount(contributionAmount);
            }
        } else {
            const payInAmount = 0.0;
            const contributionAmount = 0.0;
            setPayInAmount(payInAmount);
            setContributionAmount(contributionAmount);
        }
    };

    return (
        <>
            <section className="white">
                <form>
                    <Input
                        id="amount"
                        title="Amount to pay"
                        onChange={(e) => updatedTotalAmount(e.target.value)}
                        type="number"
                        autoComplete="off"
                    />
                    <Input
                        id="deposit"
                        title="Amount to deposit"
                        value={asMoney(payInAmount)}
                        disabled
                    />
                    <Input
                        id="contribution"
                        title="Goverment bonus"
                        value={asMoney(contributionAmount)}
                        disabled
                    />
                </form>
            </section>
        </>
    );
};

export default Form;
